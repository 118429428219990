import {
  GetTrademarkProofOfUseRenewedQuery,
  GetTrademarkProofOfUseRenewedQueryVariables,
  useGetTrademarkAffidavitRenewedQuery,
  useGetTrademarkAffidavitToRenewQuery,
  useGetTrademarkProofOfUseRenewedQuery,
  useGetTrademarkProofOfUseToRenewQuery,
  useGetTrademarkRenewedQuery,
  useGetTrademarkToRenewQuery,
} from 'generated/graphql';
import useQueryForTable from 'technical/hooks/useQueryForTable';
import { GetTrademarkProofOfUseRenewed } from './types';
import { useState } from 'react';

export const useGetRenewedData = (
  filters: {
    limit: number;
    offset: number;
    countryCode?: string[];
    office?: string;
    search?: string;
    companyRef?: string;
    dueDate?: {
      gte?: Date | null;
      lte?: Date | null;
    };
  },
  orderBy: Record<string, 'asc' | 'desc'> | null = { depositDate: 'asc' },
) => {
  const { data, loading, error, refetch } = useGetTrademarkRenewedQuery({
    fetchPolicy: 'no-cache',
    variables: {
      limit: filters.limit,
      offset: filters.offset,
      where: {
        dueDate: filters.dueDate,
        countryCode: filters.countryCode,
        office: filters.office,
        search: filters.search,
        companyRef: filters.companyRef,
      },
      orderBy,
    },
  });

  return {
    loading,
    error,
    refetch,
    data: data?.GetTrademarkRenewed?.resources ?? [],
    total: data?.GetTrademarkRenewed?.count ?? 0,
  };
};

export const useGetToRenewData = (
  filters: {
    limit: number;
    offset: number;
    countryCode?: string[];
    office?: string;
    search?: string;
    registered?: boolean;
    companyRef?: string;
    dueDate?: {
      gte?: Date | null;
      lte?: Date | null;
    };
  },
  orderBy: Record<string, 'asc' | 'desc'> | null = { nextRenewDate: 'asc' },
) => {
  const { data, loading, error, refetch } = useGetTrademarkToRenewQuery({
    fetchPolicy: 'no-cache',
    variables: {
      limit: filters.limit,
      offset: filters.offset,
      where: {
        dueDate: filters.dueDate,
        countryCode: filters.countryCode,
        office: filters.office,
        companyRef: filters.companyRef,
        search: filters.search,
        registered: filters.registered,
      },
      orderBy,
    },
  });

  return {
    loading,
    error,
    refetch,
    data: data?.GetTrademarkToRenew?.resources ?? [],
    total: data?.GetTrademarkToRenew?.count ?? 0,
  };
};

export const useGetAfuToRenew = (
  filters: {
    limit: number;
    offset: number;
    countryCode?: string[];
    search?: string;
    companyRef?: string;
    dueDate?: {
      gte?: Date | null;
      lte?: Date | null;
    };
  },
  orderBy: Record<string, 'asc' | 'desc'> | null = { dueDate: 'asc' },
) => {
  const [lastDeadlineAffidavit, setLastDeadlineAffidavit] = useState(true);
  const [excludingArgentina, setExcludingArgentina] = useState(false);
  const [firstAffidavitEvent, setFirstAffidavitEvent] = useState(false);
  const [searchAfuToRenew, setSearchAfuToRenew] = useState<string>();

  const { data, loading, error, refetch } =
    useGetTrademarkAffidavitToRenewQuery({
      fetchPolicy: 'no-cache',
      variables: {
        limit: filters.limit,
        offset: filters.offset,
        where: {
          dueDate: filters.dueDate,
          countryCode: filters.countryCode,
          search: searchAfuToRenew,
          companyRef: filters.companyRef,
          firstAffidavitEvent: firstAffidavitEvent,
          lastDeadlineAffidavit: lastDeadlineAffidavit,
          excludingArgentina: excludingArgentina,
        },
        orderBy,
      },
    });

  return {
    loading,
    error,
    setLastDeadlineAffidavit,
    setExcludingArgentina,
    setFirstAffidavitEvent,
    setSearchAfuToRenew,
    searchAfuToRenew,
    excludingArgentina,
    lastDeadlineAffidavit,
    firstAffidavitEvent,
    refetch,
    data: data?.GetTrademarkAffidavitToRenew?.resources ?? [],
    total: data?.GetTrademarkAffidavitToRenew?.count ?? 0,
  };
};

export const useGetAfuRenewed = (
  filters: {
    limit: number;
    offset: number;
    countryCode?: string[];
    search?: string;
    companyRef?: string;
    affidavitAr?: boolean;
    dueDate?: {
      gte?: Date | null;
      lte?: Date | null;
    };
  },
  orderBy: Record<string, 'asc' | 'desc'> | null = { dueDate: 'asc' },
) => {
  const [searchAfuRenewed, setSearchAfuRenewed] = useState<string>();
  const [affidavitAr, setAffidavitAr] = useState(false);
  const { data, loading, error, refetch } =
    useGetTrademarkAffidavitRenewedQuery({
      fetchPolicy: 'no-cache',
      variables: {
        limit: filters.limit,
        offset: filters.offset,
        where: {
          dueDate: filters.dueDate,
          countryCode: filters.countryCode,
          search: searchAfuRenewed,
          companyRef: filters.companyRef,
          affidavitAr: affidavitAr,
        },
        orderBy,
      },
    });

  return {
    loading,
    error,
    refetch,
    setSearchAfuRenewed,
    setAffidavitAr,
    searchAfuRenewed,
    affidavitAr,
    data: data?.GetTrademarkAffidavitRenewed?.resources ?? [],
    total: data?.GetTrademarkAffidavitRenewed?.count ?? 0,
  };
};

export const useGetProofOfUseToRenew = (
  filters: {
    limit: number;
    offset: number;
    countryCode?: string[];
    companyRef?: string;
    dueDate?: {
      gte?: Date | null;
      lte?: Date | null;
    };
  },
  orderBy: Record<string, 'asc' | 'desc'> | null = { nextRenewDate: 'asc' },
) => {
  const [searchProofOfUsedToRenew, setSearchProofOfUsedToRenew] =
    useState<string>();
  const { data, loading, error, refetch } =
    useGetTrademarkProofOfUseToRenewQuery({
      fetchPolicy: 'no-cache',
      variables: {
        limit: filters.limit,
        offset: filters.offset,
        where: {
          dueDate: filters.dueDate,
          countryCode: filters.countryCode,
          search: searchProofOfUsedToRenew,
          companyRef: filters.companyRef,
        },
        orderBy,
      },
    });

  return {
    loading,
    error,
    refetch,
    setSearchProofOfUsedToRenew,
    searchProofOfUsedToRenew,
    data: data?.GetTrademarkProofOfUseToRenew?.resources ?? [],
    total: data?.GetTrademarkProofOfUseToRenew?.count ?? 0,
  };
};

export const useGetProofOfUseRenewed = (
  filters: {
    limit: number;
    offset: number;
    countryCode?: string[];
    companyRef?: string;
    dueDate?: {
      gte?: Date | null;
      lte?: Date | null;
    };
  },
  orderBy: Record<string, 'asc' | 'desc'> | null = { depositDate: 'asc' },
) => {
  const [searchProofOfUsedRenewed, setSearchProofOfUsedRenewed] =
    useState<string>();
  const { data, loading, error, refetch } =
    useGetTrademarkProofOfUseRenewedQuery({
      fetchPolicy: 'no-cache',
      variables: {
        limit: filters.limit,
        offset: filters.offset,
        where: {
          dueDate: filters.dueDate,
          countryCode: filters.countryCode,
          search: searchProofOfUsedRenewed,
          companyRef: filters.companyRef,
        },
        orderBy,
      },
    });

  return {
    loading,
    error,
    refetch,
    setSearchProofOfUsedRenewed,
    searchProofOfUsedRenewed,
    data: data?.GetTrademarkProofOfUseRenewed?.resources ?? [],
    total: data?.GetTrademarkProofOfUseRenewed?.count ?? 0,
  };
};

export const useGetTrademarProofOfUseRenewed = (filters: any) => {
  return useQueryForTable<
    GetTrademarkProofOfUseRenewed,
    GetTrademarkProofOfUseRenewedQuery,
    GetTrademarkProofOfUseRenewedQueryVariables
  >({
    subStorageKey: `trademark_proof_of_use_renewed_table`,
    useQuery: ({ limit, offset, orderBy, searchText }) => {
      return useGetTrademarkProofOfUseRenewedQuery({
        fetchPolicy: 'no-cache',
        variables: {
          limit,
          offset,
          where: {
            dueDate: filters.dueDate,
            countryCode: filters.countryCode,
            search: searchText,
            companyRef: filters.companyRef,
          },
          orderBy: Object.keys(orderBy).length
            ? orderBy
            : {
                depositDate: 'asc',
              },
        },
      });
    },
    map: (res) => res?.GetTrademarkProofOfUseRenewed?.resources ?? [],
    getTotal: (res) => res?.GetTrademarkProofOfUseRenewed?.count ?? 0,
  });
};
