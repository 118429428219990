import { OsrFilterState } from 'business/resources/osr/services/types';
import OsrTabs from 'business/resources/osr/components/common/OsrTabs';
import Spacer from 'ui/spacer';
import Flex from 'ui/flex';
import { Checkbox, Radio, Typography } from 'antd';
import Button from 'ui/button';
import { endOfDay, endOfYear, startOfYear } from 'date-fns';
import { RangePicker } from 'ui/date/datePicker';
import { useGenerateExcelFile } from 'business/resources/osr/services/useGenerateExcelFile';
import {
  useGetRenewedData,
  useGetToRenewData,
} from 'business/resources/osr/services/osr.service';
import { useTranslation } from 'react-i18next';
import Table from 'ui/v2/table/table';
import useTableColumn from 'technical/table/useTableColumn';
import {
  RENEWED_COLOR_DESCRIPTION,
  RENEWED_COLUMNS,
  TO_RENEW_COLOR_DESCRIPTION,
  TO_RENEW_COLUMNS,
} from './constant';
import EditColumnsButton from 'ui/v2/tableActions/editColumnsButton';
import OsrColorDescription from 'business/resources/osr/components/common/osrColorDescription';
import { useState } from 'react';
import SearchBar from 'ui/v2/tableActions/search';

interface IRenewalList {
  filters: OsrFilterState;
  actions: {
    onChangeRangeDate: (from: Date | null, to: Date | null) => void;
    onChangeOffice: (office: string) => void;
  };
}

const RenewalList = (props: IRenewalList) => {
  const { t } = useTranslation();
  const { generateExcel } = useGenerateExcelFile('trademark', props.filters);
  const [searchRenewed, setSearchRenewed] = useState<string>();
  const [searchToRenew, setSearchToRenew] = useState<string>();
  const [registered, setRegistered] = useState<boolean>(true);

  const renewedTableColumns = useTableColumn(RENEWED_COLUMNS);
  const toRenewTableColumns = useTableColumn(TO_RENEW_COLUMNS);

  const {
    data: toRenewData,
    total: toRenewDataTotal,
    loading: toRenewLoading,
  } = useGetToRenewData(
    {
      limit: toRenewTableColumns.paginationState.pageSize,
      offset:
        (toRenewTableColumns.paginationState.current - 1) *
        toRenewTableColumns.paginationState.pageSize,
      companyRef: props.filters.companyRef,
      office: props.filters.office,
      dueDate: props.filters.dueDate,
      countryCode: props.filters.countryCode,
      search: searchToRenew,
      registered,
    },
    toRenewTableColumns.orderByState,
  );

  const {
    data: renewedData,
    total: renewedDataTotal,
    loading: renewedLoading,
  } = useGetRenewedData(
    {
      limit: renewedTableColumns.paginationState.pageSize,
      offset:
        (renewedTableColumns.paginationState.current - 1) *
        renewedTableColumns.paginationState.pageSize,
      companyRef: props.filters.companyRef,
      office: props.filters.office,
      dueDate: props.filters.dueDate,
      countryCode: props.filters.countryCode,
      search: searchRenewed,
    },
    renewedTableColumns.orderByState,
  );

  return (
    <OsrTabs
      actions={
        <Spacer direction="vertical" size="small">
          <Flex alignItems="center" justify="space-between">
            <Flex column style={{ width: '33%' }}>
              <RangePicker
                allowClear
                defaultValue={[startOfYear(new Date()), endOfYear(new Date())]}
                onChange={(e) => {
                  props.actions.onChangeRangeDate(
                    e && e[0] ? new Date(endOfDay(e[0])) : e?.[0] ?? null,
                    e && e[1] ? new Date(endOfDay(e[1])) : e?.[1] ?? null,
                  );
                }}
              />
            </Flex>

            <Flex>
              <Radio.Group
                defaultValue={'all'}
                onChange={(e) => props.actions.onChangeOffice(e.target.value)}
              >
                <Radio value={'all'}>
                  {t('pages.osr.filters.office', {
                    context: 'all',
                  })}
                </Radio>
                <Radio value={'Europe'}>
                  {t('pages.osr.filters.office', {
                    context: 'europe',
                  })}
                </Radio>
                <Radio value={'France'}>
                  {t('pages.osr.filters.office', {
                    context: 'france',
                  })}
                </Radio>
                <Radio value={'InterSynthese'}>
                  {t('pages.osr.filters.office', {
                    context: 'interSynth',
                  })}
                </Radio>
                <Radio value={'InterDetail'}>
                  {t('pages.osr.filters.office', {
                    context: 'interDetail',
                  })}
                </Radio>
                <Radio value={'Others'}>
                  {t('pages.osr.filters.office', {
                    context: 'others',
                  })}
                </Radio>
              </Radio.Group>
            </Flex>
          </Flex>
        </Spacer>
      }
      tabsItems={[
        {
          key: 'toRenew',
          label: `Marques à renouveler (${toRenewDataTotal})`,
          children: (
            <Spacer direction="vertical" size="small">
              <Table
                columns={toRenewTableColumns.tableColumns}
                dataSource={toRenewData}
                onChange={toRenewTableColumns.handlePagination}
                loading={toRenewLoading}
                pagination={{
                  current: toRenewTableColumns.paginationState.current,
                  pageSize: toRenewTableColumns.paginationState.pageSize,
                  total: toRenewDataTotal,
                }}
                actions={
                  <Spacer align="center">
                    <Spacer size="small" align="center">
                      <SearchBar
                        value={searchToRenew}
                        onDebouncedChange={(e) => setSearchToRenew(e)}
                      />
                      <Button
                        className="searchButton"
                        type="primary"
                        onClick={() => generateExcel('RENEWS')}
                      >
                        Exporter les Renouvellements
                      </Button>
                      <EditColumnsButton
                        initialColumns={TO_RENEW_COLUMNS}
                        visibleColumns={toRenewTableColumns.visibleColumns}
                        onChange={toRenewTableColumns.toggleColumnVisibility}
                      />
                      <Checkbox
                        checked={registered}
                        onChange={() => setRegistered(!registered)}
                      >
                        <Typography.Text>
                          Marques enregistrées uniquement
                        </Typography.Text>
                      </Checkbox>
                    </Spacer>

                    <Flex justify="flex-end">
                      <OsrColorDescription
                        colors={TO_RENEW_COLOR_DESCRIPTION}
                      />
                    </Flex>
                  </Spacer>
                }
              />
            </Spacer>
          ),
        },
        {
          key: 'renewed',
          label: `Marques renouvelées (${renewedDataTotal})`,
          children: (
            <Table
              columns={renewedTableColumns.tableColumns}
              dataSource={renewedData}
              loading={renewedLoading}
              onChange={renewedTableColumns.handlePagination}
              pagination={{
                current: renewedTableColumns.paginationState.current,
                pageSize: renewedTableColumns.paginationState.pageSize,
                total: renewedDataTotal,
              }}
              actions={
                <Spacer justify="space-between" size="small">
                  <Spacer size="small">
                    <SearchBar onDebouncedChange={(e) => setSearchRenewed(e)} />
                    <Button
                      type="primary"
                      className="searchButton"
                      onClick={() => generateExcel('RENEWS')}
                    >
                      Exporter les Renouvellements
                    </Button>
                    <EditColumnsButton
                      initialColumns={RENEWED_COLUMNS}
                      visibleColumns={renewedTableColumns.visibleColumns}
                      onChange={renewedTableColumns.toggleColumnVisibility}
                    />
                  </Spacer>
                  <Flex justify="flex-end">
                    <OsrColorDescription colors={RENEWED_COLOR_DESCRIPTION} />
                  </Flex>
                </Spacer>
              }
            />
          ),
        },
      ]}
    />
  );
};

export default RenewalList;
