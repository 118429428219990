import { TableProps } from 'antd/lib/table';
import { useMemo, useState } from 'react';

const useTableColumn = (
  initialColumns: Array<
    NonNullable<TableProps<any>['columns']>[number] & { hidden?: boolean }
  >,
) => {
  const [paginationState, setPaginationState] = useState({
    current: 1,
    pageSize: 30,
    total: 0,
  });

  const [orderByState, setOrderByState] = useState<
    Record<string, 'asc' | 'desc'> | undefined
  >(undefined);

  const [visibleColumns, setVisibleColumns] = useState(
    initialColumns
      .filter((col) => !col.hidden === true)
      .map((col) => col.key)
      .filter((key) => key !== undefined) as string[],
  );

  const toggleColumnVisibility = (columnKey: string) => {
    setVisibleColumns(
      (prev) =>
        prev.includes(columnKey)
          ? prev.filter((col) => col !== columnKey) // Retirer la colonne
          : [...prev, columnKey], // Ajouter la colonne
    );
  };

  const handlePagination: TableProps<any>['onChange'] = (
    pagination,
    _filters,
    sorter,
    _extra,
  ) => {
    setPaginationState({
      current: pagination.current || 1,
      pageSize: pagination.pageSize || 10,
      total: pagination.total || 0,
    });

    if (!Array.isArray(sorter)) {
      const field = Array.isArray(sorter.field)
        ? sorter.field[0]?.toString() || ''
        : sorter.field?.toString() || '';

      if (!field) {
        setOrderByState(undefined);
      } else {
        setOrderByState({
          [field]: sorter.order === 'ascend' ? 'asc' : 'desc', // 'ascend' ou 'descend'
        });
      }
    } else if (sorter.length > 0) {
      const primarySorter = sorter[0];
      const field = Array.isArray(primarySorter.field)
        ? primarySorter.field[0]?.toString() || ''
        : primarySorter.field?.toString() || '';

      setOrderByState({
        [field]: primarySorter.order === 'ascend' ? 'asc' : 'desc',
      });
    } else {
      setOrderByState(undefined);
    }
  };

  return {
    handlePagination,
    toggleColumnVisibility,
    tableColumns: useMemo(
      () =>
        initialColumns.filter((col) =>
          visibleColumns.includes(col.key as string),
        ),
      [visibleColumns, initialColumns],
    ),
    visibleColumns,
    paginationState,
    orderByState,
  };
};

export default useTableColumn;
